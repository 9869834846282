<template>
    <div class="collection" id="">
        <filters/>
        <div class="a-page" >
            <productListGrid/>
        </div>
    </div>
</template>

<script>
export default {
    components:{
        filters: () => import('../components/Filters'),
        productListGrid: () => import('../components/productListGrid'),
    },
    data(){
        return{
            count: 360,
            scrollY: '',
            active: false
        }
    },
    mounted(){
         
    },
    created(){
        this.init();
    },
    methods:{
        init(){
            // console.log(this.$store.state.currency)
        },
    }
}

</script>

<style scoped>
.collection{
    min-height: 100vh;
}
</style>